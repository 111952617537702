.tableExpandContent {
  display: flex;
  justify-content: center;
  align-content: center;
}
.previewPageImage {
  height: 35vh;
  min-height: 270px;
}
.templateTableTitle {
  font-size: 2rem;
  margin-left: 10px;
  display: inline-block;
}
