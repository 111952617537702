.categoryMenu {
  margin: 0 auto;
  max-width: 1200px;
  padding: 1rem 4rem 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.categoryMenu :global(.ant-input-affix-wrapper) {
  max-width: 220px;
}

.adultLabel {
  margin-right: 8px;
  color: rgb(87, 87, 87);
}

.categoryMenu :global(.ant-input-search) {
  width: 300px;
}

.templatesContent {
  margin: 0 auto;
  max-width: 1200px;
  padding: 1rem 4rem 1rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
}

/* ADD TEMPLATE BUTTON */

.addTemplateBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 320px;
  width: 290px;
  border: 1px solid #e9e9e9;
  border-radius: 7px;
  color: #333;
  font-size: 100px;
  cursor: pointer;
  transition: all 0.2s;
  margin: 0 0 1.3rem;
}

.addTemplateBtn:hover {
  color: #1890ff;
  font-size: 120px;
}

/* TEMPLATE CARD */

.templateCard {
  position: relative;
  width: 290px;
  height: 320px;
  margin: 0 0 1.3rem;
  border-radius: 7px;
  overflow: hidden;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
}

.templateCard:hover {
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.28);
  transition: 0.3s;
}

.templateCardTitle {
  font-size: 1.2rem;
  font-weight: 300;
  margin-bottom: 4px;
  color: rgb(34, 34, 34);
}

.templateCardDescription {
  height: 45px;
  font-size: .9rem;
  font-weight: 300;
}

.templateCardImage {
  width: 290px;
  height: 150px;
  object-fit: cover;
  overflow: hidden;
}

.templateCardContent {
  padding: 10px 10px 10px 16px;
  margin: 0;
  background-color: #fafafa;
  color: #000;
}

.templateCardContent p {
  margin-bottom: 5px;
}

.templateCardDate {
  font-size: 0.8rem;
  color: rgb(83, 83, 83);
}

/* 
.addMessage {
  color: rgb(238, 238, 238);
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 290px;
  width: 100%;
  flex-direction: column;
  font-size: 3rem;
  background-color: #00000000;
  transform: translateY(-300px);
  transition: all 0.4s;
}
.addMessage > p {
  font-size: 1rem;
}

.templateCard:hover .addMessage {
  transform: translateY(0px);
  background-color: #00000062;
} */

.templateSpinner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 80px;
}

.titleLink {
  color: #333;
}

/* ACTION BTNs */

.templateActionGroup {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-around;
}

.actionBtnContent {
  display: inline-block;
  width: 100%;
  border-radius: 0px;
  border: none;
  height: 100%;
  outline: none;
  cursor: pointer;
}

.actionBtnContent:hover {
  background-color: rgb(170, 170, 170);
  color: #fff;
  transition: 0.3s;
}

.actionBtnDeleteContent {
  width: 25%;
  border-radius: 0px;
  border: none;
  outline: none;
  cursor: pointer;
}

.actionBtnDeleteContent:hover {
  background-color: rgb(233, 89, 89);
  color: #fff;
  transition: 0.3s;
}

.linkActionBtn {
  width: 25%;
}

@media (max-width: 1330px) {
  .categoryMenu {
    max-width: auto;
    display: flex;
    flex-direction: column;
  }

  .categoryMenu>div {
    margin-bottom: 1rem;
  }

  .templatesContent {
    max-width: 850px;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 1030px) {
  .templatesContent {
    grid-template-columns: 1fr;
  }

  .templateCard {
    /* width: 75%; */
    /* height: auto; */
    margin: 0 0 1.3rem;
    border: 1px solid #e9e9e9;
    border-radius: 7px;
    overflow: hidden;
  }

  .templateCardImage {
    width: 100%;
    /* height: 180px; */
    object-fit: cover;
    overflow: hidden;
  }
}

@media (max-width: 600px) {
  .categoryMenu :global(.ant-input-search) {
    width: auto;
  }
}