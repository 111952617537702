.homeContainer {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 125px; /* 7.8rem */
  grid-row-gap: 30px; /* 2rem */
  justify-content: center;
  align-content: center;
  min-height: 90vh;
}
.homeText {
  grid-column: 1/4;
  text-align: center;
}

.homeCanvasLink {
  text-decoration: underline;
}

@media (max-width: 1360px) {
  .homeContainer {
    grid-column-gap: 70px;
  }
}

@media (max-width: 1200px) {
  .homeContainer {
    grid-column-gap: 30px;
  }
}
