.homeCard {
  max-width: 15.5rem;
  max-height: 22.5rem;
  box-shadow: 0px 3px 10px #00000014;
  border: 1px solid #cacaca;
  border-radius: 5px;
  text-align: center;
  padding: 2rem;
  display: grid;
  justify-items: center;
  grid-row-gap: 1rem;
  color: #4a4a4a;
  cursor: pointer;
  top: 0px;
  position: relative;
  transition: all 0.25s linear;
}

.homeCardCircle {
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
  border: inherit;
  display: grid;
  justify-items: center;
  align-items: center;
}

.homeCardDescriptionHeading h1 {
  color: #4a4a4a;
  font-size: 28px;
  font-weight: 700;
  margin: 0 0 0.5rem 0;
}

.homeCardDescriptionText p {
  font-size: 16px;
  margin: 0;
}

.homeCard:hover {
  color: #1890ff;
  top: -10px;
  box-shadow: 0px 10px 10px #00000014;
  border: 1px solid #1890ff;
}

.homeCard:hover h1 {
  color: #1890ff;
}

.homeCard:hover #icon-flash {
  color: #1890ff;
}

@media (max-width: 1100px) {
  .homeCard {
    max-width: 13rem;
    max-height: 18rem;
    padding: 0.5rem;
  }

  .homeCardCircle {
    height: 8rem;
    width: 8rem;
  }
}
