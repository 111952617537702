:root {
  --background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB0PSIxNjA2MzkyNDgwOTA4IiBjbGFzcz0iaWNvbiIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHAtaWQ9IjIyMDUiIHdpZHRoPSIyMDAiIGhlaWdodD0iMjAwIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+PGRlZnM+PHN0eWxlIHR5cGU9InRleHQvY3NzIj48L3N0eWxlPjwvZGVmcz48cGF0aCBkPSJNNzM2IDQ0OGM1MyAwIDk2LTQzIDk2LTk2IDAtNTMtNDMtOTYtOTYtOTYtNTMgMC05NiA0My05NiA5NkM2NDAgNDA1IDY4MyA0NDggNzM2IDQ0OHoiIHAtaWQ9IjIyMDYiIGZpbGw9IiM4YThhOGEiPjwvcGF0aD48cGF0aCBkPSJNOTA0IDEyOCAxMjAgMTI4Yy0zMS4yIDAtNTYgMjUuNC01NiA1Ni42bDAgNjU0LjhjMCAzMS4yIDI0LjggNTYuNiA1NiA1Ni42bDc4NCAwYzMxLjIgMCA1Ni0yNS40IDU2LTU2LjZMOTYwIDE4NC42Qzk2MCAxNTMuNCA5MzUuMiAxMjggOTA0IDEyOHpNNjk3LjggNTIzLjRjLTYtNy0xNS4yLTEyLjQtMjUuNi0xMi40LTEwLjIgMC0xNy40IDQuOC0yNS42IDExLjRsLTM3LjQgMzEuNmMtNy44IDUuNi0xNCA5LjQtMjMgOS40LTguNiAwLTE2LjQtMy4yLTIyLTguMi0yLTEuOC01LjYtNS4yLTguNi04LjJMNDQ4IDQzMC42Yy04LTkuMi0yMC0xNS0zMy40LTE1LTEzLjQgMC0yNS44IDYuNi0zMy42IDE1LjZMMTI4IDczNi40IDEyOCAyMTUuNGMyLTEzLjYgMTIuNi0yMy40IDI2LjItMjMuNGw3MTUuNCAwYzEzLjggMCAyNSAxMC4yIDI1LjggMjRsMC42IDUyMC44TDY5Ny44IDUyMy40eiIgcC1pZD0iMjIwNyIgZmlsbD0iIzhhOGE4YSI+PC9wYXRoPjwvc3ZnPg==");
}

.secondFormItem {
  border: 1px solid #c3c3c3;
  padding: 1rem;
}

.inputMarginBottom {
  margin-bottom: 0.5rem;
}

.modalFormWider {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
}

.customImageInput {
  width: 100px;
  height: 100px;
  visibility: hidden;
}

.customImageInput:before {
  content: "";
  width: 100%;
  position: absolute;
  visibility: visible;
  height: 100%;
  top: 50%;
  left: 50%;
  border: 1px solid #c3c3c3;
  transform: translate(-50%, -50%);
  background-image: var(--background);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  cursor: pointer;
}
