.logo {
  height: 25px;
  margin: 16px;
}
.logo > img {
  max-width: 100%;
  max-height: 100%;
}

.siteLayoutSubHeaderBackground {
  background: #fff !important;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* .site-layout-background {
  background: transparent;
} */
.siteLayoutBackground {
  min-height: 360px;
  padding-top: 20px;
}
.layoutContent {
  margin: 24px 16px 0;
}

/* logo and action description container */

.logoHeaderDisplay {
  height: 40px;
  padding-left: 20px;
  padding-right: 2rem;
}

.logoDescContainer {
  display: flex;
  height: 100%;
  align-items: center;
}

.buttonsRight {
  display: flex;
  position: static;
  grid-template-columns: auto auto auto;
}

#btnFix {
  line-height: 35px;
}

/* DARK MODE SWITCH */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 28px;
  margin-top: 3.5px;
  margin-left: 9px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 28px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #1a1a2e;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(30px);
  -ms-transform: translateX(30px);
  transform: translateX(30px);
}

@media (max-width: 1380px) {
  .logoHeaderDisplay {
    padding-left: 0;
    padding-right: 1rem;
  }
}
