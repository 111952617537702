.ant-col-sm-offset-4 {
  margin: 0 !important;
}

/* COMING SOON CONTENT */
.disabled {
  filter: grayscale(100%) brightness(60%) sepia(90%) hue-rotate(-50deg)
    saturate(300%) contrast(0.9) !important;
  cursor: not-allowed;
}

/* SETTING DEFAULTS */
.gjs-color-warn {
  color: #333;
}

body {
  background-color: white;
}

.generator-wrapper {
  height: 90vh;
  /* box-shadow: 3px 3px 9px 3px rgba(0, 0, 0, 0.75); */
  padding: 1rem;
}
.editor-canvas {
  position: relative;
}

.small-panel.gjs-pn-panel {
  position: absolute;
  right: 0;
  top: -45px;
}

.small-panel.gjs-pn-panel .gjs-pn-btn {
  border: 1px solid rgb(192, 192, 192);
  border-radius: none;
  color: inherit;
  text-align: center;
}

.gjs-cv-canvas {
  top: 0;
  width: 100%;
  height: 100%;
}

.sp-container {
  border: none;
  background-color: #fff;
}

.gjs-block {
  width: 7rem;
  height: 7rem;
  min-height: auto;
  padding: 0;
  position: relative;
  box-shadow: none;
  border-radius: 7px;
  border: 1px solid rgb(192, 192, 192);
}

.gjs-block:hover {
  color: #cacaca;
}

.gjs-block-label {
  font-size: 0.8rem;
  font-family: inherit;
  background-color: transparent;
}
.editor-row {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: nowrap;
  height: 300px;
}

.editor-canvas {
  flex-grow: 1;
}

.ant-layout {
  transition: all 0.3s;
}

.gjs-rte-actionbar {
  background-color: white;
}

.panel__right {
  flex-basis: 450px;
  height: 80vh;
  position: relative;
  overflow-y: auto;
  background-color: #c4c4c4;
  border-radius: 10px;
  transition: background-color 0.3s;
}
.panel__right.gjs-pn-panel,
.panel__switcher.gjs-pn-panel {
  padding: 0;
}

.panel__switcher {
  position: initial;
  width: 100%;
  height: 3rem;
  display: flex;
  align-content: center;
  transition: all 0.3s;
  background-color: transparent;
  margin-bottom: 1rem;
}

.panel__switcher .gjs-pn-buttons {
  justify-content: space-evenly;
  width: 100%;
}

.panel__switcher .gjs-pn-btn {
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  height: 100%;
  margin-right: 0;
  line-height: 43px;
  background-color: inherit;
  color: inherit;
  border-radius: 5px 0 0 5px;
  box-shadow: 0px 1px 1px 0px #d6d6d6;
}

.gjs-blocks-c {
  justify-content: center;
  background-color: transparent;
}

.gjs-blocks-no-cat {
  background-color: transparent;
}
.gjs-blocks-cs.gjs-one-bg.gjs-two-color {
  background-color: transparent;
}

.panel__switcher .gjs-pn-btn:nth-of-type(1) {
  border-radius: 5px 0px 0px 0px;
}
.panel__switcher.gjs-pn-btn:nth-of-type(2) {
  border-radius: 0px 0px 0px 0px;
}
.panel__switcher .gjs-pn-btn:nth-of-type(3) {
  border-radius: 0 5px 0px 0;
}

.gjs-one-bg {
  background-color: transparent;
}

.gjs-mdl-dialog {
  background-color: #fff;
}

.gjs-two-color {
  color: inherit;
}

.panel__switcher .gjs-pn-btn.gjs-pn-active {
  color: #fafafa;
  background-color: #cacaca;
}
.gjs-block-label {
  height: 100%;
  width: 100%;
}
.block-inner {
  height: 100%;
  width: 100%;
  display: grid;
  align-content: center;
  justify-items: center;
  grid-row-gap: 0.8rem;
  background-color: transparent;
}

.block-inner img {
  max-height: 4rem;
}

#main-buttons.gjs-pn-panel {
  position: static;
  display: grid;
  align-content: center;
  height: 100%;
  padding: 0;
  background-color: white;
  line-height: 0;
}

#main-buttons > a .gjs-pn-btn {
  height: 3rem;
}

#main-buttons .gjs-pn-buttons {
  display: flex;
  align-items: center;
}

#main-buttons.gjs-pn-panel .gjs-pn-btn,
#head-modal label.gjs-btn-prim-inverted {
  margin-left: 1rem;
  height: 35px;
  width: auto;
  background-color: white;
  color: #1890ff;
  border: 1px solid #1890ff;
  border-radius: 0;
  font-size: 16px;
  font-weight: 400;
  padding: 6px 15px 6px 15px;
}

#head-modal label.gjs-btn-prim-inverted {
  cursor: pointer;
  position: relative;
  margin: 0;
}
#head-modal label.gjs-btn-prim-inverted:focus {
  outline: none;
}

#favicon-upload {
  margin-top: 1rem;
  position: absolute;
  opacity: 0;
  z-index: -1;
}

#main-buttons .gjs-pn-btn img {
  height: 1rem;
  width: auto;
  margin-right: 0.2rem;
}

#main-buttons .gjs-pn-btn.gjs-pn-active {
  box-shadow: 1px 1px 3px 0px #1890ff;
}

#main-buttons .gjs-pn-btn.btn-last {
  grid-column: 3/4;
  grid-row: 1/1;
  color: white;
  background-color: #1890ff;
}

/* LAYER COMPONENT STYLING */
.gjs-layer {
  background-color: transparent;
}
.gjs-layer.gjs-selected .gjs-layer-title {
  background-color: #1990ffb2;
  color: #fff;
  font-weight: 600;
}
.gjs-layer-title {
  padding: 5px 7px;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.05);
}
.gjs-layer.gjs-hovered {
  background-color: #2093ff65;
}
/* DESIGN COMPONENT STYLING */

.gjs-sm-open > .gjs-sm-title {
  font-weight: 600 !important;
}

.gjs-sm-sector .gjs-sm-property .gjs-sm-btn,
.gjs-clm-tags .gjs-sm-property .gjs-sm-btn {
  text-shadow: none;
  background-color: inherit;
  border: 1px solid rgb(221, 221, 221);
  color: inherit;
  box-shadow: none;
  border-radius: 7px;
}

.styles-container > .gjs-sm-sectors > .gjs-sm-sector > .gjs-sm-title {
  background-color: inherit;
  border-bottom: none;
}
.gjs-field {
  background-color: transparent;
  border: 1px solid #ccc;
  border-radius: 7px;
  color: inherit;
}
.gjs-clm-tags #gjs-clm-new {
  color: inherit;
}

.styles-container > .gjs-sm-sectors > .gjs-sm-sector > .gjs-sm-title:hover {
  color: #b4daff;
  transition: 0.3s;
}

.gjs-fields > .gjs-field {
  background-color: inherit;
  border-radius: 7px;
  border: 1px solid rgb(221, 221, 221);
}
.gjs-four-color {
  color: inherit;
}

.gjs-sm-sector .gjs-sm-field.gjs-sm-composite,
.gjs-clm-tags .gjs-sm-field.gjs-sm-composite,
.gjs-sm-sector .gjs-sm-composite.gjs-clm-field,
.gjs-clm-tags .gjs-sm-composite.gjs-clm-field,
.gjs-sm-sector .gjs-sm-composite.gjs-clm-select,
.gjs-clm-tags .gjs-sm-composite.gjs-clm-select {
  border: none;
  /* background-color: #fafafa; */
  background-color: inherit;
}
.gjs-sm-sector .gjs-sm-field input,
.gjs-clm-tags .gjs-sm-field input,
.gjs-sm-sector .gjs-clm-field input,
.gjs-clm-tags .gjs-clm-field input,
.gjs-sm-sector .gjs-clm-select input,
.gjs-clm-tags .gjs-clm-select input,
.gjs-sm-sector .gjs-sm-field select,
.gjs-clm-tags .gjs-sm-field select,
.gjs-sm-sector .gjs-clm-field select,
.gjs-clm-tags .gjs-clm-field select,
.gjs-sm-sector .gjs-clm-select select,
.gjs-clm-tags .gjs-clm-select select {
  color: inherit;
}

.gjs-field-arrow-u {
  border-bottom: 4px solid rgb(150, 150, 150);
}
.gjs-field-arrow-d {
  border-top: 4px solid rgb(150, 150, 150);
}

.gjs-sm-properties {
  background-color: inherit;
  border-radius: 7px;
  border: 1px solid rgb(221, 221, 221);
}
.gjs-category-open,
.gjs-sm-sector.gjs-sm-open,
.gjs-sm-open.gjs-clm-tags,
.gjs-block-category.gjs-open {
  border-bottom: 3px solid #fafafa;
}

/* COLOR INPUT STYLING */
.gjs-field-color input {
  padding-right: 40px;
}
.gjs-field-colorp {
  width: 40px;
}
.gjs-field-color-picker {
  border-radius: 7px;
}
.gjs-field-colorp .gjs-checker-bg,
.gjs-field-colorp .gjs-field-colorp-c {
  border-radius: 7px;
}

.presets-image-box {
  height: 6rem;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  margin: 0.5rem 0 1rem 0;
}

.positioning-wrapper {
  display: grid;
  margin: 1rem 0 1rem 0;
  grid-template-columns: auto auto;
  justify-content: flex-start;
  grid-gap: 5px;
}

.positioning-wrapper .positioning-icon-box {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
}

.positioning-wrapper .positioning-icon-box img {
  height: 100%;
  width: 100%;
}

.positioning-wrapper .positioning-icon-box:hover {
  box-shadow: 2px 1px 3px 3px rgba(0, 0, 0, 0.25);
}

.gjs-am-preview-cont {
  height: 40px;
  width: 20%;
}

.ant-layout-header {
  padding: 0 40px;
}

.alert-wrapper {
  display: grid;
  grid-template-columns: 1.5fr 3.5fr;
  justify-content: flex-start;
  align-items: center;
  grid-column-gap: 1rem;
  grid-row-gap: 0.5rem;
}

.alert-wrapper #alert-text {
  width: 100%;
}

.extensions-radio-collection,
.fp-radio-collection {
  margin-left: 1.5rem;
}

.extensions-radio-collection label,
.fp-radio-collection label:first-of-type {
  margin-right: 0.5rem;
}

.CodeMirror {
  height: 80vh;
}

.custom-save-import-button {
  margin-top: 0.8rem;
  margin-right: 0.8rem;
  width: 5rem;
  background-color: #1890ff;
  color: white;
  border-radius: 5px;
  font-size: 1.1rem;
  transition: background-color 0.2s ease;
}

.custom-save-import-button:hover {
  background-color: #094a86;
}

.gjs-fonts::before {
  font-size: 7em;
  color: #cacaca;
}

/* BOX SHADOW BOXES */
.gjs-sm-sector .gjs-sm-property .gjs-sm-layer #gjs-sm-close-layer {
  top: -5px;
}
.gjs-sm-sector .gjs-sm-stack #gjs-sm-add {
  color: #333;
  font-size: 30px;
}

#head-modal .CodeMirror {
  height: 30vh;
}

.selection-wrapper {
  height: 60vh;
  overflow-y: auto;
}

.gjs-radio-item input:checked + .gjs-radio-item-label {
  background-color: #c3c3c3;
}

.gjs-field-checkbox input:checked + .gjs-chk-icon {
  border-color: black;
}

.flex-icon.gjs-radio-item-label {
  height: 28px;
}
.flex-icon {
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.flex-al-center {
  background-image: url("/resources/flex/flex-al-center.png");
}
.flex-al-end {
  background-image: url("/resources/flex/flex-al-end.png");
}
.flex-al-start {
  background-image: url("/resources/flex/flex-al-start.png");
}
.flex-al-stretch {
  background-image: url("/resources/flex/flex-al-str.png");
}
.flex-dir-col-rev {
  background-image: url("/resources/flex/flex-dir-col-rev.png");
}
.flex-dir-col {
  background-image: url("/resources/flex/flex-dir-col.png");
}
.flex-dir-row-rev {
  background-image: url("/resources/flex/flex-dir-row-rev.png");
}
.flex-dir-row {
  background-image: url("/resources/flex/flex-dir-row.png");
}
.flex-just-end {
  background-image: url("/resources/flex/flex-just-end.png");
}
.flex-just-sp-ar {
  background-image: url("/resources/flex/flex-just-sp-ar.png");
}
.flex-just-sp-bet {
  background-image: url("/resources/flex/flex-just-sp-bet.png");
}
.flex-just-sp-cent {
  background-image: url("/resources/flex/flex-just-sp-cent.png");
}
.flex-just-start {
  background-image: url("/resources/flex/flex-just-start.png");
}

.gjs-rte-actionbar {
  background: #012d6d;
  color: white;
}

.gjs-rte-action {
  border-right: none;
  font-size: 1.1rem !important;
}
.gjs-rte-action:hover {
  background-color: #1351af;
}

.gjs-rte-action .gjs-field {
  border: none;
}
.gjs-rte-action .gjs-field:active,
.gjs-rte-action .gjs-field:focus {
  outline: none;
  border: none;
}

.gjs-rte-action .gjs-field option {
  color: black;
  font-size: 0.9rem;
}

.gjs-rte-active {
  background: #366cd6;
}

@media (max-width: 1480px) {
  #main-buttons.gjs-pn-panel .gjs-pn-btn {
    font-size: 14px;
    padding: 6px 10px 6px 10px;
  }
  .ant-layout-header {
    padding: 0 10px;
  }
}

@media (max-width: 1250px) {
  #main-buttons.gjs-pn-panel .gjs-pn-btn {
    margin-left: 0.5rem;
  }
}
