button.btn-variant-1 {
    color: #fff;
    background-color: hsla(0, 0%, 100%, 0.2);
    border: 2px solid #fff;
    border-radius: 5px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2),
        inset 1px 1px 2px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    font-size: 22px;
    font-weight: 600;
    height: 80px;
    margin-right: 5px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
    transition-property: border-color, color, background-color, box-shadow;
    transition-duration: 0.25s;
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
}

button.btn-variant-1:hover {
    background-color: #fff;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2), inset 0 0 0 rgba(0, 0, 0, 0.00001);
    color: #202020;
}

button.btn-variant-2 {
    background-color: rgba(0, 232, 113, 0.1);
    border: 2px solid #00e871;
    color: #00e871;
    border-radius: 5px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2),
        inset 1px 1px 2px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    font-size: 22px;
    font-weight: 600;
    height: 80px;
    margin-right: 5px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
    transition-property: border-color, color, background-color, box-shadow;
    transition-duration: 0.25s;
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
}

button.btn-variant-2:hover {
    background-color: #00e871;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2), inset 0 0 0 rgba(0, 0, 0, 0.00001);
    color: #202020;
}

button.btn-variant-3 {
    background-color: #4caf50;
    border: 0;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
}